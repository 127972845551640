@import "./variables";
@import "./mixins";
// @import "../components/Nav/nav.styles.scss";

h1,
h2,
h3,
h4,
h5 {
  font-family: $main-font;
}
p {
  font-family: $secondary-font;
}
.carousel-accesories-container {
  .carousel {
    @include breakpoint($md) {
      height: 100vh !important;
    }
  }
}

.modal {
  &-dialog {
    @include breakpoint($md) {
      max-width: 650px;
    }
  }
  &-header {
    background-color: #ffde59;
    border: 0;
  }
  &-body {
    padding: 0;
    background-color: #ffde59;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -webkit-touch-callout: none;
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
img {
  pointer-events: none;
}
