@import '../../scss/variables';
@import '../../scss/mixins';

.blog__item {
	&-container {
		@include breakpoint($md) {
			display: flex;
			margin-left: -0.75rem;
			width: auto;
		}

		&__column {
			@include breakpoint($md) {
				padding-left: 0.75rem;
			}
		}
	}
}
