@import "../../scss/variables";
@import "../../scss/mixins";

#banner {
  height: 500px;
  margin-bottom: 0;
  // background-image: linear-gradient(
  //   rgba(0, 0, 0, 0.73),
  //   rgba(0, 0, 0, 0.275)
  // ) !important;
  @include breakpoint($md) {
    margin-top: -101px;
    height: 100vh;
  }
  h1 {
    font-size: 3rem;
    text-align: center;
    text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
    @include breakpoint($md) {
      font-size: 5rem;
    }
  }
}
.uk-section {
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(0, 0, 0, 0.43),
  //     rgba(0, 0, 0, 0.43)
  //   ),
  //   url("../../assets/article-background.jpg");
  background-position: center;
  background-color: #1a4391;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint($md) {
    // min-height: 90vh;
    padding-bottom: 30px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    color: #fff;
  }
  ul {
    li {
      font-family: $secondary-font;
      & strong {
        font-weight: bolder;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
      }
    }
  }
  p {
    // display: flex;
    // align-items: flex-start;
    // @include breakpoint($md-down) {
    //   flex-direction: column;
    //   line-height: 27px;
    //   text-align: justify;
    // }
    text-align: justify;
    @include breakpoint($md-down) {
      line-height: 27px;
    }
    &:first-of-type {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;
      @include breakpoint($md-down) {
        flex-direction: column;
        line-height: 27px;
        margin-bottom: 2rem;
      }
    }
    strong {
      font-weight: bolder;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
    }
    img {
      display: flex;
      width: 100%;
      height: auto;
      &:nth-child(2n) {
        margin-left: 0;
        margin-top: 1.5rem;
        @include breakpoint($md) {
          margin-top: 0;
          margin-left: 2rem;
        }
      }
    }
  }
  .related-content {
    border-top: 1px solid rgba(255, 255, 255, 0.37);
    margin-top: 4rem;
    padding: 1.5rem;
    h3 {
      color: #fff;
      font-size: 3rem;
    }
    .container-fluid {
      display: flex;
      flex-direction: column;
      @include breakpoint($md) {
        flex-direction: row;
      }
      a.article-card {
        padding: 0;
        margin-top: 1rem;
        @include breakpoint($md) {
          margin-top: 0;
          width: 50%;
          height: auto;
        }
        &:nth-child(2) {
          @include breakpoint($md) {
            margin: 0 1rem;
          }
        }
        h4 {
          font-size: 1.5rem;
          text-align: center;
        }
        p {
          font-size: 1rem;
          text-align: center;
          margin: 0;
          display: none;
          @include breakpoint($ipad-port) {
            display: block;
            width: 100%;
            font-size: 0.9rem !important;
          }
        }
      }
    }
  }
}

.blog-article {
  padding: 2rem;
  display: grid;
  grid-template-columns: 45% 1fr;
  gap: 3rem;
  &__media {
    position: relative;
    & > div {
      position: sticky;
      top: 1rem;
    }
    &::before {
      content: "";
      background-color: #eb8d30;
      height: 40px;
      width: 200px;
      position: absolute;
      top: 1rem;
      left: -2rem;
      z-index: 9;
    }
    &__title {
      background-color: #eeeeee;
      padding: 2rem 0;
      h1 {
        font-family: $secondary-font;
        text-align: center;
        span {
          color: #1a4391;
          font-weight: bolder;
        }
      }
    }
  }
  &__body {
    p,
    ol {
      line-height: 1.7;
      color: #676767;
    }
    p {
      line-height: 1.7;
      color: #676767;
    }
    ol {
      font-family: $secondary-font;
      li {
        font-family: $secondary-font;
        font-size: 2rem;
        line-height: 1;
        color: #1a4391;
        font-weight: bolder;
        span {
          font-size: 1rem;
          color: #676767;
          font-weight: lighter;
        }
      }
    }
  }
}
