@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &-landing {
      // background-image: url("../../assets/aluminio-jumbotron.jpg");
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 800px;
      padding: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      z-index: 1000;
      padding: 0 !important;
      margin-top: -106px !important;
      margin-bottom: 0 !important;
      @include breakpoint($md) {
        margin-top: -8rem !important;
        height: 105vh;
      }
      .video {
        height: 100%;
        width: 200%;
        object-fit: cover;
      }
      img {
        &:not(:first-child) {
          display: block;
          margin-left: auto;
        }
      }
      h1 {
        font-size: 4.5rem;
        text-align: right;
        color: #fff;
        // font-size: 2rem;
        @include breakpoint($md) {
          font-size: 6rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
      p {
        @include breakpoint($md) {
          font-size: 1.5rem;
        }
      }
      .container {
        position: absolute;
        width: 100%;
        max-width: none;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-top: 2rem;
        @include breakpoint($md) {
          padding-top: 3.5rem;
        }
        .row {
          height: 100%;
        }
      }
      &__characteristics {
        background-image: url("../../assets/tuberia-caracteristicas-jumbotron.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint($md) {
          height: 90vh;
          text-align: center;
        }
        h1,
        p {
          color: #fff;
        }
      }
    }
  }
  &--landing {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.73),
        rgba(0, 0, 0, 0.63)
      ),
      url("../../assets/accesorios-barandales-landing.jpeg");
    background-size: cover;
    background-position: center;
    margin-bottom: 0;

    height: auto;
    padding: 2rem;
    @include breakpoint($md) {
      padding: 4rem;
    }
    @include breakpoint($lg) {
      min-height: 90vh;
    }
    h1 {
      color: #fff;
      font-size: 3rem;
      @include breakpoint($md) {
        font-size: 6rem;
        width: 60%;
      }
    }
    &__list {
      margin-top: 2rem;
      div {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 2rem;
        }
        h3 {
          margin-bottom: 0;
          color: #fff;
          font-family: $secondary-font;
        }
      }
    }
    &--aluminum {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.01)
        ),
        url("../../assets/img-automotriz.jpg");
      background-size: cover;
      background-position: center;
      margin-bottom: 0;

      height: auto;
      padding: 2rem;
      @include breakpoint($md) {
        padding: 4rem;
      }
      @include breakpoint($lg) {
        height: 92vh;
      }
      @include breakpoint($xl) {
        min-height: 80vh;
      }
      h1 {
        color: #fff;
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 6rem;
          width: 60%;
        }
      }
      &__list {
        margin-top: 2rem;
        div {
          display: flex;
          align-items: center;
          &:not(:first-child) {
            margin-top: 2rem;
          }
          h3 {
            margin-bottom: 0;
            color: #fff;
            font-family: $secondary-font;
          }
          img {
            @include breakpoint($md) {
              height: 80px;
            }
            @include breakpoint($xl) {
              height: 90px;
            }
          }
        }
      }
    }
    &--acero {
      background-image: url("../../assets/img-acero.jpeg");
      background-size: cover;
      background-position: center;
      margin-bottom: 0;

      height: auto;
      padding: 2rem;
      @include breakpoint($md) {
        padding: 4rem;
      }
      @include breakpoint($lg) {
        height: auto;
      }
      @include breakpoint($xl) {
        min-height: 80vh;
      }
      h1 {
        color: #fff;
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 6rem;
          width: 60%;
        }
      }
      &__list {
        margin-top: 2rem;
        div {
          display: flex;
          align-items: center;
          &:not(:first-child) {
            margin-top: 2rem;
          }
          h3 {
            margin-bottom: 0;
            color: #fff;
            font-family: $secondary-font;
          }
          img {
            margin-right: 1rem;
            @include breakpoint($md) {
              height: 60px;
            }
            @include breakpoint($xl) {
              height: 90px;
            }
          }
        }
      }
    }
    &--acero-inoxidable {
      background-image: url("../../assets/bg-acero.jpeg");
      background-size: cover;
      background-position: center;
      margin-bottom: 0;

      height: auto;
      padding: 2rem 0;
      @include breakpoint($md) {
        padding: 4rem;
      }
      @include breakpoint($lg) {
        height: 95vh;
      }
      @include breakpoint($xl) {
        min-height: 80vh;
      }
      h1 {
        color: #fff;
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 5rem;
        }
      }
      .card {
        background-color: rgba(5, 68, 130, 0.45);
        border: 0;
        border-radius: 0;
        text-align: center;
        padding: 3rem 1rem;
        height: 100%;
        h3,
        h4 {
          color: transparent;
          -webkit-text-stroke: 1px rgb(255, 255, 255);
        }
        h3 {
          color: transparent;
          font-size: 2.5rem;
          -webkit-text-stroke: 1px rgb(255, 255, 255);
        }
        h4 {
          font-size: 2.5rem;
          &:not(:last-child) {
            margin-right: 0.8rem;
            @include breakpoint($md) {
              margin-right: 2rem;
            }
          }
        }
        h5 {
          color: #fff;
          font-size: 2rem;
        }
        p {
          color: #fff;
        }
      }
    }
  }
}

.about {
  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    @include breakpoint($md) {
      padding: 3rem 2rem;
    }
    img {
      width: 40%;
      height: 90%;
      @include breakpoint($md) {
        width: 50%;
        height: 95%;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      h1,
      h2,
      h3,
      h4 {
        color: rgba(6, 70, 130, 1);
        font-family: $secondary-font;
        font-size: 2.2rem;
        margin-bottom: 0;
        @include breakpoint($md) {
          font-size: 3.2rem;
        }
      }
      h1,
      h3,
      h4 {
        line-height: 35px;
      }
      h1 {
        font-weight: lighter;
      }
      h2,
      h3,
      h4 {
        font-weight: bolder;
        text-transform: uppercase;
      }
      h3,
      h4 {
        font-size: 1.9rem;
      }
    }
  }
  &__group {
    padding: 2rem 1rem;
  }
  &__certifications {
    padding-bottom: 3rem;
    h1 {
      font-size: 2rem;
      margin-bottom: 0;
      @include breakpoint($md) {
        font-size: 3.5rem;
      }
    }
  }
  &__benefits {
    background-color: rgba(6, 70, 130, 1);
    padding: 2rem 1rem !important;
    @include breakpoint($lg) {
      padding: 4rem !important;
    }
    h2,
    p {
      color: #fff;
    }
    h2 {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 1.5rem;
      @include breakpoint($md) {
        font-size: 4rem;
      }
    }
    p {
      margin-bottom: 0;
      margin-left: 0.8rem;
    }
    img {
      width: 70px;
    }
  }
  &-2 {
    height: auto;
    @include breakpoint($md) {
      height: 600px;
    }
    &__tuberia-conduit {
      background-position: 50% 50%;
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 3rem 0;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.274)
        ),
        url("../../assets/img-tuberia-04.jpeg");
      @include breakpoint($md) {
        padding: 60px;
        padding-left: 3rem !important;
      }
      h1 {
        color: #fff;
        font-size: 5.5rem;
      }
      &__list {
        margin-top: 2rem;
        div {
          display: flex;
          align-items: center;
          img {
            height: 70px;
          }
          h3 {
            font-family: $secondary-font;
            color: #fff;
            font-size: 1.1rem;
          }
        }
      }
    }
    &__certifications {
      padding: 1.5rem 0;
      text-align: center;
      h2,
      h1 {
        color: #515151;
      }
      h1 {
        font-size: 4rem;
      }
      h2 {
        font-size: 3rem;
      }
      img {
        height: 80%;
        width: auto;
      }
      .row {
        margin-top: 2rem;
      }
    }
  }
}

.accesories {
  &-lineup {
    padding: 2rem 0;
    h2 {
      text-align: center;
      color: rgb(77, 77, 77);
      @include breakpoint($md) {
        font-size: 6.25rem;
      }
    }
    img {
      @include breakpoint($xs) {
        margin-top: 1rem;
      }
    }
    &--tuberia {
      padding: 2rem 0;
      h2 {
        text-align: center;
        color: $main-blue;
        @include breakpoint($md) {
          font-size: 6.25rem;
        }
      }
    }
  }
}
.branches {
  padding: 2rem 0 4rem 0;
  h1 {
    color: $main-blue;
    font-size: 4rem;
    @include breakpoint($md) {
      font-size: 6rem;
    }
  }
}
.catalogue {
  padding-bottom: 2rem;
  @include breakpoint($md) {
    padding-bottom: 4rem;
  }
  h3 {
    text-align: center;
    color: rgb(77, 77, 77);
    @include breakpoint($md) {
      font-size: 6.25rem;
    }
  }
}
.carousel--accesories {
  @include breakpoint($xs) {
    height: 350px !important;
  }
  &-container {
    position: relative;
    .carousel {
      height: 300px;
      @include breakpoint($md) {
        height: 80vh;
      }
      img {
        height: 100%;
      }
      &-inner,
      &-item {
        height: 100%;
      }
    }
  }
  &-caption {
    &--top {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      left: 9%;
      text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 200px;
      @include breakpoint($md) {
        left: 15%;
      }
      h4 {
        color: #fff;
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 5rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
    }
    &--bottom {
      position: absolute !important;
      bottom: 5% !important;
      right: 3% !important;

      // text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      // padding-top: 20px;
      // padding-bottom: 20px;
      @include breakpoint($md) {
        right: 10% !important;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 1.2rem;
        @include breakpoint($md) {
          font-size: 2rem;
        }
      }
    }
  }
  &-inner {
    height: 100%;
  }
  &-item {
    height: 100%;
    // img {
    //   height: 100%;
    // }
  }
}
.carousel--tuberia {
  &-container {
    // @include breakpoint($xs) {
    //   height: 350px !important;
    // }
    position: relative;
    .carousel {
      height: 250px !important;
      @include breakpoint($md) {
        height: 100vh !important;
      }
      &-inner,
      &-item {
        height: 100%;
      }
      &-item {
        text-align: center;
        img {
          width: 100%;
          height: auto;

          @include breakpoint($md) {
            width: 100%;
            height: auto !important;
          }
        }
        // background-image: linear-gradient(
        //   to bottom,
        //   rgba(0, 0, 0, 0.73),
        //   rgba(0, 0, 0, 0.63)
        // );

        // img {
        //   object-fit: cover;
        // }
        // img {
        //   height: 100%;
        // }
      }
    }
  }
  &-caption {
    &--top {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      left: 0;
      text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      @include breakpoint($md) {
        // left: 15%;
      }
      h4 {
        color: #fff;
        font-size: 3rem;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        @include breakpoint($md) {
          font-size: 5rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
    }
    &--bottom {
      position: absolute !important;
      bottom: 5% !important;
      right: 0;

      // text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      // padding-top: 20px;
      // padding-bottom: 20px;
      @include breakpoint($md) {
        // right: 0;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 1rem;
        width: 80%;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        margin-left: auto;
        @include breakpoint($md) {
          font-size: 2rem;
          width: 60%;
          text-align: right;
        }
      }
    }
  }
  &-inner {
    height: 100%;
  }
}
.carousel--aluminum-landing {
  &-container {
    // @include breakpoint($xs) {
    //   height: 350px !important;
    // }
    position: relative;
    .carousel {
      height: 1500px;
      @include breakpoint($md) {
        height: 100vh !important;
      }
      &-inner,
      &-item {
        height: 100%;
      }
      &-item {
        text-align: center;
        padding: 3rem 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &--1 {
          background-image: url("../../assets/landing-aluminio-carrusel-1.jpg");
        }
        &--2 {
          background-image: url("../../assets/landing-aluminio-carrusel-2.jpg");
        }
        &--3 {
          background-image: url("../../assets/landing-aluminio-carrusel-3.jpg");
        }
        &--4 {
          background-image: url("../../assets/landing-aluminio-carrusel-4.jpg");
        }
        &-card {
          background-color: #2a5ab3a2;
          height: 93%;
          padding: 2rem 1rem;
          &__heading {
            h2 {
              color: #fff;
              font-size: 4.5rem;
            }
            h3 {
              color: transparent;
              font-size: 2.5rem;
              -webkit-text-stroke: 1px rgb(255, 255, 255);
            }
            h4 {
              color: transparent;
              -webkit-text-stroke: 1px rgb(255, 255, 255);
              font-size: 2.5rem;
              &:not(:last-child) {
                margin-right: 1rem;
                @include breakpoint($md) {
                  margin-right: 4rem;
                }
              }
            }
            // > div {
            // }
          }
          &__properties {
            h5 {
              color: #fff;
              font-size: 2.5rem;
            }
          }
          &__property {
            p {
              color: #fff;
            }
          }
        }
        // img {
        //   width: 100%;
        //   height: auto;

        //   @include breakpoint($md) {
        //     width: 100%;
        //     height: auto !important;
        //   }
        // }
        // background-image: linear-gradient(
        //   to bottom,
        //   rgba(0, 0, 0, 0.73),
        //   rgba(0, 0, 0, 0.63)
        // );

        // img {
        //   object-fit: cover;
        // }
        // img {
        //   height: 100%;
        // }
      }
    }
  }
  &-caption {
    &--top {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      left: 0;
      text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      @include breakpoint($md) {
        // left: 15%;
      }
      h4 {
        color: #fff;
        font-size: 3rem;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        @include breakpoint($md) {
          font-size: 5rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
    }
    &--bottom {
      position: absolute !important;
      bottom: 5% !important;
      right: 0;

      // text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      // padding-top: 20px;
      // padding-bottom: 20px;
      @include breakpoint($md) {
        // right: 0;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 1rem;
        width: 80%;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        margin-left: auto;
        @include breakpoint($md) {
          font-size: 2rem;
          width: 60%;
          text-align: right;
        }
      }
    }
  }
  &-inner {
    height: 100%;
  }
}
.carousel--aluminum {
  &__applications {
    &-container {
      height: 600px;
      position: relative;
      @include breakpoint($md) {
        // margin-bottom: 4rem;
        height: 101vh;
      }
      // @include breakpoint($m) {
      // }
      .carousel {
        height: 100%;
        &-item {
          img {
            width: 120%;
          }
        }
        &-caption {
          &--top {
            position: absolute !important;
            top: 0 !important;
            right: 0 !important;
            left: 9%;
            text-align: unset !important;
            // bottom: 20px;
            z-index: 10;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 200px;
            @include breakpoint($md) {
              left: 15%;
            }
            h4 {
              color: #fff;
              font-size: 3rem;
              @include breakpoint($md) {
                font-size: 5rem;
              }
              @include breakpoint($lg) {
                font-size: 8rem;
              }
            }
          }
          &--bottom {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            padding: 0 2rem;
            padding-bottom: 1rem;
            position: absolute !important;
            bottom: 5% !important;
            right: 3% !important;
            @include breakpoint($md) {
              flex-direction: row;
            }
            & > div {
              &:first-child {
                width: 300px;
                @include breakpoint($md) {
                  width: 600px;
                }
                // @include breakpoint($lg) {
                //   width: 800px;
                // }
              }
            }
            p,
            ul,
            li,
            h4 {
              color: #fff;
              margin: 0;
            }
            li {
              text-transform: uppercase;
              font-family: $main-font;
            }
          }
        }
      }
    }
    &__item {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5 {
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
        padding: 8rem 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include breakpoint($md) {
          padding: 10rem 14rem;
        }
        @include breakpoint($lg) {
          padding: 10rem 18rem;
          height: 900px;
        }
        h2 {
          font-size: 2.5rem;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          @include breakpoint($md) {
            font-size: 4rem !important;
            width: 67%;
          }
          @include breakpoint($lg) {
            font-size: 9rem !important;
            width: 100%;
          }
        }
      }
      &--1 {
        background-image: url("../../assets/img-aluminio-03.jpg") !important;
      }
      &--2 {
        background-image: url("../../assets/soldaduras.jpg") !important;
      }
      &--3 {
        background-image: url("../../assets/img-aluminio-06.jpg") !important;
      }
      &--4 {
        background-image: url("../../assets/plane-prominox.jpg") !important;
      }
      &--5 {
        background-image: url("../../assets/Minisplit.jpg") !important;
      }
    }
  }
}
.carousel {
  &--steel {
    &-container {
      .carousel {
        @include breakpoint($xs) {
          height: 1000px !important;
        }
        // @include breakpoint($md) {
        //   height: 900px !important;
        // }
      }
    }
    &__applications {
      &-container {
        height: 600px;
        position: relative;
        @include breakpoint($md) {
          height: 100vh;
        }
        // @include breakpoint($xl) {
        //   height: 900px;
        // }
        .carousel {
          height: 100% !important;
          margin-bottom: 3rem;
          &-item {
            background: linear-gradient(rgb(2, 2, 2) 0%, rgba(2, 2, 2, 0) 100%);

            img {
              width: 120%;
            }
          }
          &-caption {
            &--top {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              left: 9%;
              text-align: unset !important;
              // bottom: 20px;
              z-index: 10;
              padding-top: 20px;
              padding-bottom: 20px;
              width: 200px;
              @include breakpoint($md) {
                left: 15%;
              }
              h4 {
                color: #fff;
                font-size: 3rem;
                @include breakpoint($md) {
                  font-size: 5rem;
                }
                @include breakpoint($lg) {
                  font-size: 8rem;
                }
              }
            }
            &--bottom {
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              padding: 0 2rem;
              padding-bottom: 1rem;
              position: absolute !important;
              bottom: 5% !important;
              right: 3% !important;

              @include breakpoint($md) {
                flex-direction: row;
              }
              & > div {
                &:first-child {
                  width: 300px;
                }
              }
              p,
              ul,
              li {
                color: #fff;
                margin: 0;
              }
              li {
                text-transform: uppercase;
                font-family: $main-font;
              }
              p {
                color: #fff;
                text-transform: uppercase;
                font-family: $main-font;
                font-size: 1.2rem;
                @include breakpoint($md) {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
      &__item {
        &--1,
        &--2,
        &--3,
        &--4 {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 800px;
          padding: 8rem 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          @include breakpoint($md) {
            padding: 10rem 14rem;
          }
          @include breakpoint($lg) {
            padding: 10rem 18rem;
            height: 900px;
          }
          h2 {
            // color: #fff;
            font-size: 3rem;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            @include breakpoint($md) {
              font-size: 4rem !important;
              width: 67%;
              margin-top: 3rem;
            }
            @include breakpoint($lg) {
              font-size: 6rem !important;
              width: 100%;
            }
          }
        }
        &--1 {
          background-image: url("../../assets/aplicaciones-acero-01.jpg") !important;
        }
        &--2 {
          background-image: url("../../assets/refineries.jpg") !important;
        }
        &--3 {
          background-image: url("../../assets/acero-inoxidable-adornos-arquitectonicos.jpg") !important;
        }
        &--4 {
          background-image: url("../../assets/scientist.jpg") !important;
        }
      }
    }
    &-item {
      h2,
      h3,
      h4,
      h5,
      p {
        color: #fff;
      }
      h2 {
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 7rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        @include breakpoint($md) {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          font-size: 4.5rem;
        }
      }
      h4 {
        font-size: 2rem;
        @include breakpoint($md) {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          font-size: 3.5rem;
        }
      }
      h5 {
        font-size: 2rem;
        @include breakpoint($md) {
          font-size: 3.5rem;
        }
      }
      &--1,
      &--2 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &--1 {
        background-image: url("../../assets/img-acero-b-1.jpg");
      }
      &--2 {
        background-image: url("../../assets/img-acero-b-1.jpg");
        .carousel--steel-item__heading {
          width: 100%;
          & > div {
            width: 65%;
            margin: 0 auto;
          }
        }
      }
      &__heading {
        text-align: center;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
      &__properties {
        text-align: center;
        margin-top: 3rem;
        &__card {
          margin-top: 2rem;
          p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
