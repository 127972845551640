@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &-centro-de-servicio,
    &-branches {
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      height: 800px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      .video {
        @include breakpoint($xs) {
          width: 0;
          height: 0;
        }
        height: 100%;
        width: 200%;
        object-fit: cover;
      }
      .container {
        position: absolute;
        width: 100%;
        top: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include breakpoint($ipad-port) {
          top: 0;
        }
      }
      @include breakpoint($md) {
        height: 105vh;
        margin-top: -8rem !important;
      }
      h1,
      h4,
      p {
        color: #fff;
        text-align: center;
      }
      h1 {
        font-family: $main-font;
        font-size: 4rem;
        text-transform: uppercase;
        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
        @include breakpoint($md) {
          font-size: 6rem;
        }
      }
      h4 {
        font-family: $secondary-font;
        text-transform: none;
        font-size: 1.2rem;
        max-width: 90%;
        margin: 0 auto;
        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
        @include breakpoint($md) {
          font-size: 1.9rem;
          max-width: 60%;
        }
      }
    }
    &-centro-de-servicio {
      background-image: url("../../assets/centro-servicio-mobile-bg.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    // &-branches {
    //   background-image: url("../../assets/sucursales-jumbotron.jpg");
    // }
  }
  &-blog {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.73),
        rgba(252, 252, 252, 0.274)
      ),
      url("../../assets/jumbotron-blog.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0 !important;
    @include breakpoint($md) {
      height: 100vh;
    }
    h1,
    h2 {
      color: #fff;
    }
    h1 {
      font-size: 7rem;
      margin: 0;
      text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
      @include breakpoint($md) {
        font-size: 12rem;
      }
      @include breakpoint($lg) {
        font-size: 20rem;
      }
    }
    h2 {
      font-size: 3.5rem;
      margin-top: -2rem;
      @include breakpoint($md) {
        font-size: 5rem;
        margin-top: -3rem;
      }
      @include breakpoint($lg) {
        font-size: 7rem;
        margin-top: -5rem;
      }
    }
  }
  &-category {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0 !important;
    @include breakpoint($md) {
      height: 100vh;
      margin-top: -101px !important;
    }
    h1 {
      color: #fff;
      margin: 0 auto;
      font-size: 4rem;
      @include breakpoint($md) {
        font-size: 7rem;
        width: 40%;
      }
    }
  }
}
