$xs: (
	max: 767px,
);
$sm: (
	min: 769px,
);
$md-down: (
	max: 991px,
);
$md: (
	min: 992px,
);
$lg: (
	min: 1366px,
);
$xl: (
	min: 1900px,
);
$ipad-port: (
	min: 768px,
	max-h: 1024px,
);
$ipad-land: (
	min: 1024px,
	max-h: 768px,
);
$ipad-pro-port: (
	min: 1024px,
	max-h: 1366px,
);
$ipad-pro-land: (
	min: 1366px,
	max-h: 1024px,
);
$sm-only: (
	min: map-get($sm, min),
	max: map-get($md, min) - 1,
);
$md-only: (
	min: map-get($md, min),
	max: map-get($lg, min) - 1,
);

@mixin breakpoint($map) {
	$query: '';
	@if map-has-key($map, min) {
		$query: append($query, '(min-width: #{map-get($map, min)})');
	}
	@if map-has-key($map, min) and map-has-key($map, max) {
		$query: append($query, 'and');
	}
	@if map-has-key($map, max) {
		$query: append($query, '(max-width: #{map-get($map, max)})');
	}
	@media screen and #{$query} {
		@content;
	}
}

@mixin baseTransition($time) {
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
}

@mixin flex-start() {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@mixin flex-end() {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@mixin flex-center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-between() {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
