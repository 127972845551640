@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &-accesories {
      background-image: url("../../assets/barandarles-mobile-bg.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 800px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 !important;
      @include breakpoint($md) {
        height: 105vh;
        margin-top: -8rem !important;
      }
      .video {
        @include breakpoint($xs) {
          width: 0;
          height: 0;
        }
        height: 100%;
        width: 200%;
        object-fit: cover;
      }
      h1 {
        font-size: 4.5rem;
        text-align: center;
        color: #fff;
        @include breakpoint($lg) {
          font-size: 6rem;
        }
      }
      .container {
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &__cards {
      &__container {
        @include breakpoint($md) {
          max-width: 900px;
          margin: 0 auto;
        }
      }
    }
    &__card {
      display: flex;
      align-items: center;
      img,
      p {
        margin: 0;
      }
      p {
        color: #fff;
      }
    }
  }
}
.accesories {
  &-lineup {
    padding: 2rem 0;
    h2 {
      text-align: center;
      color: rgb(77, 77, 77);
      @include breakpoint($md) {
        font-size: 6.25rem;
      }
    }
    img {
      @include breakpoint($xs) {
        margin-top: 1rem;
        height: 166px;
        max-width: 150px;
      }
      @include breakpoint($md) {
        height: 300px;
        max-width: 300px;
      }
      @include breakpoint($lg) {
        height: 360px;
        max-width: 330px;
      }
    }
  }
}
.catalogue {
  padding-bottom: 2rem;
  @include breakpoint($md) {
    padding-bottom: 4rem;
  }
  h3 {
    text-align: center;
    color: rgb(77, 77, 77);
    @include breakpoint($md) {
      font-size: 6.25rem;
    }
  }
}
.carousel--accesories {
  @include breakpoint($xs) {
    height: 350px !important;
  }
  &-container {
    position: relative;
    @include breakpoint($md) {
      height: 100vh !important;
    }
    .carousel {
      height: 300px !important;
      @include breakpoint($md) {
        height: 100vh !important;
      }
      img {
        height: 100%;
      }
      &-inner,
      &-item {
        height: 100%;
      }
    }
  }
  &-caption {
    &--top {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      left: 9%;
      text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 200px;
      @include breakpoint($md) {
        left: 15%;
      }
      h4 {
        color: #fff;
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 5rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
    }
    &--bottom {
      position: absolute !important;
      bottom: 5% !important;
      right: 3% !important;

      // text-align: unset !important;
      // bottom: 20px;
      z-index: 10;
      // padding-top: 20px;
      // padding-bottom: 20px;
      @include breakpoint($md) {
        right: 10% !important;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 1.2rem;
        @include breakpoint($md) {
          font-size: 2rem;
        }
      }
    }
  }
  &-inner {
    height: 100%;
  }
  &-item {
    height: 100%;
    // img {
    //   height: 100%;
    // }
  }
}
