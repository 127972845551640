@import "../../scss/variables";
@import "../../scss/mixins";

.category {
  background-color: #cdcecf;
  height: 100%;
  overflow-x: hidden;
  padding: 0;
  @include breakpoint($md) {
    padding: 0.75rem 0 0;
  }
  &-container {
    position: relative;
    .card-columns {
      @include breakpoint($lg) {
        column-count: 2;
        .card {
          &:nth-child(odd) {
            padding-left: 1rem;
          }
        }
      }
    }
    .container-fluid {
      padding: 0;
      h1 {
        z-index: 9;
        position: relative;
      }
      .row {
        .col-lg-6 {
          margin-top: 1rem;
          padding-left: 0;
          &:nth-child(odd) {
            height: 430px;
            .article-card {
              &-media-top {
                @include breakpoint($lg) {
                  height: auto;
                  overflow-y: hidden;
                }
              }
            }
          }
          &:nth-child(even) {
            margin-top: 1rem;
            height: 470px;
            .article-card {
              &-media-top {
                img {
                  @include breakpoint($lg) {
                    height: 100%;
                    width: 100%;
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            margin-top: 0;
          }
          &:nth-child(3) {
            @include breakpoint($lg) {
              margin-top: -1.5rem;
              height: 750px;
              .article-card {
                &-media-top {
                  @include breakpoint($lg) {
                    height: auto;
                    overflow-y: hidden;
                    img {
                      height: 100%;
                      width: auto;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(4) {
            @include breakpoint($lg) {
              margin-top: 1rem;
              height: 380px;
              .article-card {
                &-media-top {
                  @include breakpoint($lg) {
                    height: auto;
                    overflow-y: hidden;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(6) {
            @include breakpoint($lg) {
              margin-top: -20rem;
              height: 320px;
              .article-card {
                &-media-top {
                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.category--v2 {
  display: grid;
  grid-template-areas:
    "slider"
    "articles";
  position: relative;
  &__articles {
    // height: 1000px;
    height: 103vh;
    .primary-article {
      height: 500px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      position: relative;
      &__content {
        position: absolute;
        right: 2rem;
        top: 2rem;
        width: 40%;
        h1 {
          position: relative;
          padding-top: 1rem;
          &::before {
            content: "";
            background-color: #eb8d30;
            height: 6px;
            width: 50px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        p {
          line-height: 1.7;
          color: #3f3f3f;
        }
      }
    }
    .secondary-article {
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      position: relative;
      &__heading {
        position: absolute;
        bottom: -4.5rem;
        left: 0;
        h3 {
          position: relative;
          padding-top: 1rem;
          &::before {
            content: "";
            background-color: #000;
            height: 6px;
            width: 50px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  &__secondary-articles {
    // position: sticky;
    // top: 2rem;
    // right: 0;
    // background-image: linear-gradient(180deg, #313280 0%, #0a2856 100%);
    // height: 100%;
    padding: 1rem;
    max-height: 103vh;
    overflow-y: scroll;
    background-color: #e4e4e3;
    h5 {
      span {
        font-size: 0.8rem;
        font-family: $secondary-font;
      }
    }
  }
  &__secondary-article {
    position: relative;
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
    &:nth-child(2n + 1) {
      &::before {
        content: "";
        background-color: $main-blue;
        height: 20px;
        width: 50px;
        position: absolute;
        top: 1rem;
        left: -0.5rem;
        z-index: 9;
      }
    }
    &:nth-child(2n) {
      &::before {
        content: "";
        background-color: #eb8d30;
        height: 20px;
        width: 50px;
        position: absolute;
        top: 1rem;
        right: -0.5rem;
        z-index: 9;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    h4 {
      font-family: $secondary-font;
      font-size: 1.1rem;
      margin-top: 0.5rem;
    }
    &__media {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @include breakpoint($md) {
    grid-template-columns: 1fr 27%;
    grid-template-areas:
      "articles slider"
      "articles slider";
  }
}
