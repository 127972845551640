@import "../../scss/variables";
@import "../../scss/mixins";

.thank-you {
  margin-top: -110px;
  z-index: 999;
  position: relative;
  display: block;
  background-color: #fff;
  min-height: 70vh;
  @include breakpoint($md) {
    margin-top: -101px;
  }
  h1 {
    margin-bottom: 0;
    color: $main-blue;
    font-size: 3.5rem;
    position: relative;
    @include breakpoint($md) {
      font-size: 4rem;
    }
    @include breakpoint($md-down) {
      &::before {
        content: "";
        width: 50%;
        background-color: $main-blue;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    @include breakpoint($md) {
      &::after {
        content: "";
        width: 2px;
        background-color: $main-blue;
        height: 100px;
        position: absolute;
        top: -1rem;
        right: -1rem;
      }
    }
  }
  .video {
    height: 250px;
    width: 100%;
    object-fit: cover;
    @include breakpoint($md) {
      height: 550px;
    }
    @include breakpoint($lg) {
      height: 590px;
    }
    @include breakpoint($xl) {
      height: 620px;
    }
  }
  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    p {
      margin-bottom: 0;
      @include breakpoint($md-down) {
        margin-top: 1.5rem;
      }
    }
  }
}
