.video {
  height: 100%;
  width: 200%;
  object-fit: cover;
}
.content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.subContent {
  text-align: center;
  padding: 10px;
}
