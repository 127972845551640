@import "../../scss/variables";
@import "../../scss/mixins";

.white-line {
  background-color: #fff;
  width: 80px;
  height: 5px;
  z-index: 9;
  margin-bottom: 1rem;
  opacity: 0;
}

.card {
  &-category {
    position: relative;
    display: inline-block;
    &::after {
      transition: all 0.2s ease-in;
      content: "";
      background-color: #0057b380;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
    }
    &:hover {
      &::after {
        opacity: 1;
      }
      .card-category-body {
        opacity: 1;
        h5 {
          color: #fff;
          z-index: 9;
          text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
        }
        p,
        .white-line {
          opacity: 1;
        }
      }
    }
    &-media-top {
      height: 99%;
      overflow: hidden;
      position: relative;
      img {
        @include breakpoint($md) {
          height: auto;
          width: 100%;
        }
      }
    }
    &-body {
      position: absolute;
      top: 0;
      // left: 50%;
      // background: #ebedef1c;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      // align-items: flex-end;
      justify-content: center;
      align-items: center;
      padding-right: 2rem;
      @include breakpoint($xs) {
        padding: 1rem;
      }
      @include breakpoint($md) {
        padding: 1rem;
        width: 100%;
        height: 100%;
      }
      @include breakpoint($lg) {
        width: 710px;
        height: 100%;
      }
      h5 {
        color: #000;
        opacity: 1;
        font-size: 5rem;
      }
      p {
        color: #fff;
        z-index: 9;
        opacity: 0;
      }
      h5 {
        font-family: $main-font;

        @include breakpoint($md) {
          font-size: 4rem;
        }
      }
      p {
        // position: absolute;
        // top: 45%;
        // left: 35%;
        // opacity: 1;
        font-size: 1rem;
        position: relative;
        text-align: center;
        @include breakpoint($md) {
          width: 80%;
        }
      }
    }
  }
}
.article-card {
  display: flex;
  position: relative;
  height: 100%;
  @include breakpoint($xs) {
    flex-direction: column;
    padding: 0 1rem;
  }
  &::after {
    transition: all 0.2s ease-in;
    content: "";
    background-color: #0057b380;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
  }
  &:hover {
    text-decoration: none;
    &::after {
      opacity: 1;
    }
    .white-line,
    p {
      opacity: 1;
    }
    h4 {
      text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
    }
    .article-card-body {
      display: none;
    }
    .article-card-body--hover {
      display: flex;
    }
  }
  &-media-top {
    overflow: hidden;
    // @include breakpoint($xs) {
    //   max-height: 227px;
    // }
    // @include breakpoint($ipad-port) {
    //   max-width: 40%;
    // }
  }
  &-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding-top: 1rem;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    @include breakpoint($ipad-port) {
      padding: 1rem;
    }
    @include breakpoint($md) {
      padding: 1rem;
    }
    &--hover {
      // opacity: 0;
      align-items: center;
      flex-direction: column;
      height: auto;
      justify-content: center;
      padding-top: 1rem;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      display: none;
      z-index: 999;
      @include breakpoint($ipad-port) {
        padding: 1rem;
      }
    }
    .white-line {
      margin-bottom: 0.7rem;
    }
    h4,
    p {
      color: #fff;
      z-index: 9;
    }
    h4 {
      text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
      @include breakpoint($md) {
        font-size: 2rem !important;
        // width: 70%;
        margin: 0 auto;
        text-align: center;
      }
    }
    p {
      text-transform: none;
      opacity: 0;
      @include breakpoint($md) {
        font-size: 1.5rem;
      }
    }
  }
}

.blog,
.category {
  &__item {
    display: block;
    @include breakpoint($md) {
      margin-bottom: 0.75rem;
    }
    &:hover {
      text-decoration: none;
    }
    &-holder {
      text-align: center;
      position: relative;
      @include baseTransition(0.3s);
      height: 100%;
      * {
        @include baseTransition(0.3s);
      }
      &:hover {
        .blog,
        .category {
          &__item {
            &__content-container {
              background-color: #0057b380;
            }
            &__text {
              opacity: 1;
            }
          }
        }
        .white-line {
          opacity: 1;
          margin: 0 auto 1rem auto;
        }
      }
    }
    &__title {
      color: #fff;
      text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
      font-size: clamp(2.5rem, 4.4vw, 4rem);
    }
    &__text {
      color: #fff;
      @include breakpoint($md) {
        opacity: 0;
      }
    }
    &__content {
      width: 80%;
      height: 90%;
      @include flex-center;
      flex-direction: column;
      &-container {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex-center;
        @include breakpoint($md-down) {
          background-color: rgba(0, 87, 179, 0.1);
        }
      }
      @include breakpoint($md-down) {
        width: 90%;
        height: 100%;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include breakpoint($md-down) {
        min-height: 300px;
      }
    }
  }
}
