@import "../../scss/variables";

a {
  &.btn-quote--blue {
    span {
      background-color: $main-blue;
      min-height: 50px !important;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}
