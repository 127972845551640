@import "../../scss/variables";
@import "../../scss/mixins";

.branch-card {
  min-width: 300px !important;
  @include breakpoint($ipad-port) {
    &:last-child {
      max-width: 355px;
    }
  }
  @include breakpoint($md) {
    &:last-child {
      max-width: 310px;
    }
  }
  @include breakpoint($lg) {
    max-width: 330px !important;
  }
  margin-top: 1rem;
  .card {
    &-header {
      height: 170px;
      overflow: hidden;
      border-radius: 0 !important;
      background-color: #fff !important;
      border-bottom: none !important;
      padding: 0 !important;
      @include breakpoint($xl) {
        height: 230px;
      }
      // img {
      //   margin-top: -0.2rem;
      // }
    }
    &-body {
      padding: 1rem;
      .second-img {
        width: 40%;
        margin-bottom: 1rem;
        margin-left: 4rem;
      }
      & > div {
        display: flex;
        align-items: flex-start;
        &:not(:first-child) {
          margin-top: 0.6rem;
        }
        img {
          width: 30px;
          height: auto;
        }
        a {
          font-family: $secondary-font;
          font-size: 0.9rem;
          color: #000;
          margin-left: 0.5rem;
        }
        p {
          margin: 0;
          font-size: 0.9rem;
          margin-left: 0.5rem;
        }
      }
    }
    &-img-top {
      @include breakpoint($xl) {
        height: 90%;
        width: 120%;
      }
    }
    &-title {
      font-family: $main-font;
      font-size: 1.7rem;
      letter-spacing: 0.5px;
      border-bottom: 1px solid rgb(223, 223, 223);
    }
  }
}
