// Color variables
$main-blue: #003495;
$yellow: #ffc928;
$main-red: #751816;
$medium-red: #4f0c0d;
$dark-red: #43050d;
$light-blue: #91e5f6;
$aqua: #48a9a6;
$dark-grey: #171717;

// Font variables
$main-font: "Bebas Neue", cursive;
$secondary-font: "Montserrat", sans-serif; ;
