@import "../../scss/variables";
@import "../../scss/mixins";

.branches {
  &-container {
    padding: 3rem 0;
  }
}
.jumbotron--hero-branches {
  background-image: url("../../assets/sucursales-jumbotron.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint($md) {
    margin-top: -8rem !important;
    height: 105vh;
  }
}
// .branch {
//   &-card {
//     .card-header {
//       height: 400px;
//     }
//   }
// }
