@import "../../scss/variables";
@import "../../scss/mixins";

.contact {
  background-image: url("../../assets/contact-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1090px;
  padding: 5rem 0;
  @include breakpoint($md-down) {
    min-height: 1050px;
  }
  h2 {
    color: #fff;
    font-size: 5rem;
    text-align: center;
    @include breakpoint($md) {
      font-size: 6rem;
    }
  }
  label {
    font-family: $main-font;
    color: $main-blue;
    @include breakpoint($md-down) {
      font-size: 1.2rem;
    }
  }
  &-form__error {
    color: rgb(201, 51, 51);
    background-color: rgb(249, 210, 210);
    border-radius: 5px;
    padding: 10px;
    text-align: center;
  }
  &__form {
    &__container {
      padding: 2rem 1rem;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.712);
      @include breakpoint($md) {
        padding: 4rem 3rem;
        width: 80%;
      }
      .form-group {
        margin-bottom: 0.5rem;
      }
      input,
      select {
        border-radius: 0;
      }
      textarea {
        white-space: pre-wrap;
      }
      span {
        color: red;
        font-family: $secondary-font;
        font-size: 0.9rem;
        margin-top: 5px;
      }
      button {
        background-color: $main-blue;
        font-family: $secondary-font;
        color: #fff;
        border: none;
        padding: 0.8rem 2.5rem;
        @include breakpoint($md-down) {
          width: 100%;
        }
      }
    }
  }
}
