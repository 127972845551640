@import "../../scss/variables";
@import "../../scss/mixins";

.centro-de-servicio {
  padding: 2rem 0;
  @include breakpoint($md) {
    min-height: 100vh;
    padding: 5rem 0;
  }
  .nav.nav-pills {
    font-family: $main-font;
    font-size: 1.2rem;
  }
  .nav-link {
    color: #000;
  }
  .nav.nav-pills .nav-link.active {
    background-color: $main-blue;
    border-radius: 0;
  }
  .tab-pane {
    @include breakpoint($xs) {
      margin: 1rem 0;
      padding: 0 1rem;
    }
    ul {
      position: relative;
      padding-left: 0;
      margin-top: 1rem;
      @include breakpoint($md) {
        columns: 2;
      }
      &.third {
        @include breakpoint($md) {
          columns: 3;
        }
      }
      li {
        list-style: none;
        &::before {
          list-style: none;
          content: "";
          display: inline-block;
          height: 1rem;
          width: 1rem !important;
          background-image: url(../../assets/bullet--blue.svg);
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.5em;
        }
        &:not(:first-of-type) {
          margin-top: 0.6rem;
        }
        p {
          padding-left: 1.5rem;
          &:first-of-type {
            margin-top: 0.5rem;
          }
        }
        span {
          font-family: $main-font;
          font-size: 1.1rem;
        }
      }
    }
  }
  .video {
    // @include breakpoint($xs) {
    //   width: 0;
    //   height: 0;
    // }
    // @include breakpoint($xs) {
    //   height: 400px;
    // }
    // @include breakpoint($lg) {
    //   max-height: unset;
    // }
  }
}
