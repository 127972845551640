@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    margin: 0 !important;
    height: 800px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    @include breakpoint($xs) {
      background-image: url("../../assets/home-mobile-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 800px;
    }
    @include breakpoint($md) {
      height: 104vh;
      margin-top: -8rem !important;
    }
    .video {
      @include breakpoint($xs) {
        width: 0;
        height: 0;
      }
      height: 100%;
      width: 200%;
      object-fit: cover;
    }
    .container {
      position: absolute;
      width: 100%;
      top: 15%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include breakpoint($ipad-port) {
        top: 0;
      }
    }
    h1,
    h4,
    p {
      color: #fff;
      text-align: center;
    }
    h1 {
      font-family: $main-font;
      font-size: 6rem;
      text-transform: uppercase;
      text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
      &:nth-child(2n) {
        font-family: $secondary-font;
        text-transform: none;
        font-size: 1.2rem;
        max-width: 90%;
        margin: 0 auto;
        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.55);
        @include breakpoint($md) {
          font-size: 1.9rem;
          max-width: 60%;
        }
      }
    }
    a {
      color: #fff;
      display: block;
      transition: transform 0.2s ease-in-out;
      &:hover {
        color: #fff;
        transform: translateY(-10px);
        text-decoration: none;
      }
    }
    p {
      font-size: 1.2rem;
      transition: all 0.3s ease-in-out;
      @include breakpoint($md) {
        max-width: 60%;
        margin: 0 auto;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      height: 94px;
      @include breakpoint($lg) {
        height: 100%;
      }
    }
    .row {
      margin-top: 2rem;
    }
  }
}
.about-us {
  align-items: center;
  background-image: url("../../assets/about.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 3rem 0;
  margin: 0;
  @include breakpoint($ipad-port) {
    height: 85vh;
    padding: 0;
  }
  @include breakpoint($md) {
    height: 101vh;
  }
  h2,
  h5,
  p {
    color: #fff;
  }
  h2 {
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem;
    margin-bottom: 4rem;
    @include breakpoint($md) {
      font-size: 5rem;
      margin-bottom: 0;
    }
  }
  .row {
    @include breakpoint($md) {
      margin-top: 4rem;
    }
  }
  &__card {
    display: flex;
    @include breakpoint($md) {
      min-height: 178px;
      justify-content: center;
    }
    &:last-child {
      margin-top: 1rem;
    }
    &--body {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 20px;
      @include breakpoint($md) {
        padding: 10px 0 0 20px;
      }
      h5 {
        text-transform: uppercase;
        font-size: 2rem;
      }
      p {
        font-weight: 300;
        font-size: 0.9rem;
        @include breakpoint($lg) {
          opacity: 0.75;
        }
      }
    }
    img {
      height: 100%;
      width: auto;
      &.intertek {
        height: 100px;
        width: auto;
      }
    }
  }
}
.products {
  align-items: center;
  background-image: url("../../assets/products.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 1700px;
  justify-content: center;
  @include breakpoint($md) {
    height: 100vh;
  }
  h3,
  h5,
  p {
    color: #fff;
  }
  h3,
  h5 {
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    font-size: 3.5rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);

    @include breakpoint($md) {
      font-size: 5rem;
    }
  }
  h5 {
    font-size: 1.7rem;
    margin-top: 1rem;
  }
  .row {
    @include breakpoint($md) {
      margin-top: 4rem;
    }
  }
  .product {
    &__card {
      margin-bottom: 2rem;
      display: block;
      @include breakpoint($md) {
        margin-bottom: 0;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: translateY(-15px);
          text-decoration: none;
        }
      }
      h5 {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
        font-size: 2rem;
        letter-spacing: 1px;
        @include breakpoint($md) {
          font-size: 1.5rem;
        }
      }
      img {
        display: block;
        margin: 0 auto;
        &:first-child {
          width: 75%;
          @include breakpoint($ipad-port) {
            width: 40%;
          }
          @include breakpoint($md) {
            width: 100%;
          }
        }
      }
    }
  }
}
.coverage {
  background-image: url("../../assets/coverage-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  padding: 3rem 0;
  @include breakpoint($ipad-port) {
    height: 900px;
  }
  @include breakpoint($md) {
    height: 800px;
  }
  h4 {
    text-align: center;
    font-size: 4rem;
    color: #fff;
    @include breakpoint($md) {
      font-size: 6.2rem;
    }
  }
  img {
    display: block;
    margin: 2rem auto;
    width: 95%;
    @include breakpoint($md) {
      width: 60%;
      margin: 0 auto;
    }
  }
  &__cities {
    display: flex;
    flex-direction: column;
    height: 500px;
    margin-top: 1rem;
    align-items: center;
    @include breakpoint($md) {
      margin-top: 0;
    }
    ul {
      columns: 2;
      padding-left: 1rem;
      width: 100%;
      @include breakpoint($md) {
        columns: 4;
        margin-left: 2rem;
        padding-left: 0;
      }
      li {
        list-style: none;
        a {
          color: #fff;
          text-transform: uppercase;
          font-family: $main-font;
          font-size: 1.3rem;
          &::before {
            content: "";
            display: inline-block;
            height: 1.2rem;
            width: 1rem;
            background-image: url("../../assets/location-icon--white.svg");
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}
.why-us {
  background-image: url("../../assets/why-us-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1050px;
  padding: 5rem 0;
  @include breakpoint($md) {
    height: 100vh;
  }
  h5 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    @include breakpoint($lg) {
      font-size: 4.5rem;
    }
  }
  &__individual {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    justify-content: center;
    h2,
    p {
      color: #fff;
    }
    h2 {
      font-size: 4.5rem;
    }
    p {
      font-size: 1.2rem;
      margin-left: 1rem;
      font-weight: lighter;
      width: 80%;
      @include breakpoint($md) {
        margin-bottom: 0;
        font-size: 1.7rem;
        width: 60%;
      }
    }
    & > div {
      width: 120px;
      text-align: center;
    }
    img {
      height: 60px;
      width: 60px;
      @include breakpoint($md) {
        height: 75px;
        width: 75px;
      }
    }
  }
}
.industrial-sectors {
  background-color: $dark-grey;
  padding: 3rem 0;
  height: 2380px;
  @include breakpoint($md) {
    padding: 5rem 0;
    height: 800px;
  }
  h5 {
    color: #fff;
    font-size: 3rem;
    text-align: center;
    @include breakpoint($md) {
      font-size: 6rem;
    }
  }
  &__grid {
    @include breakpoint($md-down) {
      margin-top: 2rem;
    }
    &__container {
      display: flex;
      flex-direction: column;
      @include breakpoint($md) {
        flex-direction: row;
      }
    }
    &--aero,
    &--auto,
    &--build,
    &--food,
    &--transport,
    &--home,
    &--furniture,
    &--electro,
    &--gob,
    &--minery,
    &--gas,
    &--electric {
      align-items: center;
      background-position: center !important;
      background-repeat: no-repeat;
      background-size: cover !important;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 180px;
      justify-content: center;
      width: 100%;
      filter: saturate(0%) opacity(75%);
      transition: all 0.2s ease;
      @include breakpoint($md) {
        min-width: 190px;
      }
      @include breakpoint($lg) {
        min-width: 280px;
      }
      &:hover {
        filter: saturate(100%);
      }
      p {
        color: #fff;
        font-size: 1rem;
        margin-top: 0.6rem;
        text-transform: uppercase;
        @include breakpoint($lg) {
          font-size: 1.3rem;
        }
      }
      img {
        height: 60px;
      }
    }
    &--auto {
      background: url("../../assets/img-automotriz.jpg");
    }
    &--aero {
      background: url("../../assets/img-aeroespacial.jpg");
    }
    &--build {
      background: url("../../assets/img-construccion.jpg");
    }
    &--food {
      background: url("../../assets/img-alimenticio.jpg");
    }
    &--transport {
      background: url("../../assets/img-transporte.jpg");
    }
    &--home {
      background: url("../../assets/img-homeappliance.jpg");
    }
    &--furniture {
      background: url("../../assets/img-muebles.jpg");
    }
    &--electro {
      background: url("../../assets/img-electrodomesticos.jpg");
    }
    &--gob {
      background: url("../../assets/img-gobierno.jpg");
    }
    &--minery {
      background: url("../../assets/img-mineria.jpg");
    }
    &--gas {
      background: url("../../assets/img-oilandgas.jpg");
    }
    &--electric {
      background: url("../../assets/img-electrico.jpg");
    }
  }
}
.contact {
  background-image: url("../../assets/contact-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1050px;
  padding: 5rem 0;

  h2 {
    color: #fff;
    font-size: 5rem;
    text-align: center;
    @include breakpoint($md) {
      font-size: 6rem;
    }
  }
  label {
    font-family: $main-font;
    color: $main-blue;
  }
  &__form {
    &__container {
      padding: 2rem 1rem;
      margin: 0 auto;
      @include breakpoint($md) {
        padding: 4rem 3rem;
        width: 80%;
      }
      input,
      select {
        border-radius: 0;
      }
      button {
        background-color: $main-blue;
        font-family: $secondary-font;
        color: #fff;
        border: none;
        padding: 0.8rem 2.5rem;
      }
    }
  }
}

.usa-float {
  p {
    position: fixed;
    bottom: 9rem;
    right: 20px;
    background-color: #103595;
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 0.9rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 9;
    // &:hover + a{

    // }
  }
  a {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 4rem;
    right: 30px;
    background-color: #fff;
    border-radius: 50px;
    text-align: center;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    @include breakpoint($md) {
      bottom: 5rem;
      &:hover {
        transform: translateY(-10px);
        & + p {
          opacity: 1;
        }
      }
    }
    // &:hover + p {
    //   opacity: 1;
    // }
    img {
      height: 47px;
      @include breakpoint($md) {
        height: 43px;
      }
    }
  }
  &:hover {
  }
}
